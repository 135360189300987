import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Autocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import ErrorIcon from "../../../images/fi_alert-triangle.svg";
import Logo from "../../../images/Frame 1321317892.png";
import IconAlert from "../../../images/fi_alert-circle.png";
import PDFIcon from "../../../images/pdf.png";
import DownloadIcon from "../../../images/fi_download.png";
import DemandeFo from "../../../images/demandeFournisseur.png";
import fi_calendar from "../../../images/fi_calendar.png";
import { useHistory } from "react-router-dom";
import { addDemandeCommandeFournisseur } from "./DataService";
import { Badge, Card, Col, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "../../../../src/style/commande.css";
import { FormatageDate } from "../../../utilities";
import { fr } from "date-fns/locale";
import dayjs from "dayjs";

const FormSupplier = () => {
  const history = useHistory();
  const currentTimePlusOneHour = dayjs().add(1, 'hour');
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Adresse e-mail invalide")
      .required("L'adresse e-mail est obligatoire"),
    clientName: Yup.string().required("Le nom du client est obligatoire"),
    clientPrenom: Yup.string().required("Le prénom du Client est obligatoire"),
    steName: Yup.string().required(
      "Le raison social de l'entreprise est obligatoire"
    ),
    telephone: Yup.string()
      .matches(
        /^[0-9]{10}$/,
        "Le numéro de téléphone doit comporter 10 chiffres"
      )
      .required("Le numéro de téléphone est obligatoire"),
    comment: Yup.string(),
    pickupAdr: Yup.string().required("Adresse Pick up est requise"),
    address: Yup.string().required("Adresse de Livraison est requise"),
    steClient: Yup.string().required("Raison Sociale est required"),
    // deliveryDateTime: Yup.string().required(
    //   "La date et l'heure de livraison sont obligatoires"
    // ),
    deliveryDateTime: Yup.string()
    .required("La date et l'heure de livraison sont obligatoires")
    .test("is-valid-time", "L'heure de livraison doit commencer à partir du 08:00", (value) => {
      const deliveryTime = dayjs(value);
      new Date(value)
      const hour = deliveryTime.hour();
      return hour >= 8 ;
    })
    .test("is-future-time", "L'heure de livraison doit être au moins 1 heure et 10 min après l'heure actuelle", (value) => {
      const deliveryTime = dayjs(value);
      return deliveryTime.isAfter(currentTimePlusOneHour);
    }),

    deliveryReceipt: Yup.mixed()
      .required("Le reçu de livraison au format PDF est obligatoire")
      .test(
        "fileSize",
        "Fichier trop volumineux",
        (value) => value && value.size <= 25000000
      ),
  });


// Get the current time in the timezone of France 
const  franceTime = new Date().toLocaleString("fr-FR", { timeZone: "Europe/Paris" }); 

console.log("franceTime",franceTime)
// Convert to a Date object and extract the hours
 const currentHourInFrance = new Date(franceTime).getHours();

 console.log("currentHourInFrance",currentHourInFrance)
 const isDelay = currentHourInFrance >=16;
  const [address, setAddress] = useState("");
  const [pickupAdr, setPickUpAdr] = useState("");
  const [file, setFile] = useState(null);
  const [Error, setError] = useState("");
  const [ErrorPickUp, setErrorPickUp] = useState("");
  const [deliveryLatLng, setDeliveryLatLng] = useState(null);
  const [pickupLatLng, setPickupLatLng] = useState(null);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [emailStorage, setEmailStorage] = useState(userData?.email);
  const [startDate, setStartDate] = useState(new Date());
  const [raisonSocialStorage, setRaisonSocialStorage] = useState(
    userData?.user_profil?.RaisonSocial
  );
  const [fileName, setFileName] = useState(""); // State to store the file name


  const handleChangeAddress = async (address, type, setFieldValue) => {
    if (type === "pickup") {
      setPickUpAdr(address);
      setFieldValue("pickupAdr", address);
    } else {
      setAddress(address);
      setFieldValue("address", address);
    }
  };

  const handleSelect = async (address, type, setFieldValue) => {
    try {
      const results = await geocodeByAddress(address);
      if (results.length > 0) {
        const { lat, lng } = await getLatLng(results[0]);
        if (type === "pickup") {
          setPickUpAdr(address);
          setFieldValue("pickupAdr", address);
          setFieldValue("longitudePickUp", lng);
          setFieldValue("altitudePickUp", lat);
          setPickUpAdr(address);
          setPickupLatLng({ lat, lng });
        } else {
          setFieldValue("address", address);
          setAddress(address);
          setFieldValue("altitudeLivraison", lat);
          setFieldValue("longitudeLivraison", lng);
          setDeliveryLatLng({ lat, lng });
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const HandleSave = async (values) => {
    const formattedDate = FormatageDate(values.deliveryDateTime);
    const userData = JSON.parse(localStorage.getItem("userData"));
    const myFormData = new FormData();
    myFormData.append("file", file);
    myFormData.append("id_fournisseur", userData.id);
    myFormData.append("adressPickUp", pickupAdr);
    myFormData.append("email", values.email);
    myFormData.append("adresseLivraison", address);
    myFormData.append("dateLivraison", formattedDate);
    myFormData.append("tel", values.telephone);
    myFormData.append("raisonSocial", values.steName);
    myFormData.append("raisonSocialClient", values.steClient);
    myFormData.append("nomClient", values.clientName);
    myFormData.append("prenomClient", values.clientPrenom);
    myFormData.append("longitudePickUp", pickupLatLng?.lng);
    myFormData.append("altitudePickUp", pickupLatLng?.lat);
    myFormData.append("altitudeLivraison", deliveryLatLng?.lat);
    myFormData.append("longitudeLivraison", deliveryLatLng?.lng);
    myFormData.append("commantaire", values.comment);
    const response = await addDemandeCommandeFournisseur(myFormData, history);
  };

  const searchOptions = {
    componentRestrictions: { country: ["fr"] },
    types: ["address"],
  };
  const logout = () => {
    localStorage.clear();
    window.location.href = "/login";
  };
  useEffect(() => {}, [emailStorage, raisonSocialStorage]);

  return (
    <>
      <div className="PageFournisseur">
        <div className="header row headerSection">
          <div className="col-md-6">
            {" "}
            <img src={Logo} alt="logo marion" width="100px" />
          </div>
          <div className="col-md-6 d-flex justify-content-end">
            <button onClick={logout} className="btn logoutBtn">
              Se déconnecter
            </button>
          </div>
        </div>
        <Row className="contenaire">
          <Col md="4" className="left-side">
            <img
              src={DemandeFo}
              alt="DemandeFournisseur"
              className="demandeFournisseur"
            />
          </Col>
          <Col md="8" className="right-side mainBgColor">
            <h4 className="pt-5 title-style">Commande fournisseur</h4>
            <Row className="layout alert-message">
              <Col xs="auto">
                <img src={IconAlert} alt="Alert Icon" className="iconAlert" />
              </Col>
              <Col className="text-container colorBlue">
                Marion accepte les livraisons du lundi au vendredi de 8h à 17h
                et le samedi de 9h à 12h. Passé ce délai, les commandes seront
                traitées le prochain jour ouvrable.
              </Col>
            </Row>

            <Formik
              initialValues={{
                email: emailStorage,
                clientName: "",
                clientPrenom: "",
                steName: raisonSocialStorage,
                steClient: "",
                telephone: "",
                comment: "",
                deliveryDateTime: "",
                deliveryReceipt: "",
                pickupAdr: "",
                address: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting }) => {
                HandleSave(values);
                // setSubmitting(false);
              }}
            >
              {({
                errors,
                touched,
                setFieldValue,
                isSubmitting,
                handleSubmit,
                handleBlur,
                values,
              }) => (
                <Form onSubmit={handleSubmit} className="mt-40">
                  <Card className="mb-3 cardDemandeFo">
                    <Card.Body>
                      <Card.Title className="pb-3 colorTitle textCard">
                        1. Informations fournisseurs
                      </Card.Title>
                      <Row>
                        <Col>
                          <div className="form-floating mb-3">
                            <Field
                              type="email"
                              name="email"
                              className="form-control backWhite"
                              id="floatingInputemail"
                              placeholder=""
                              value={values?.email || emailStorage}
                              onChange={(e) => {
                                const value = e.target.value;
                                setEmailStorage(value);
                                setFieldValue("email", value);
                              }}
                            />
                            <label
                              htmlFor="floatingInputemail"
                              className="colorInput"
                            >
                              Adresse e-mail
                            </label>
                            {touched.email && errors.email && (
                              <div className="text-start mt-8 d-flex">
                                <img
                                  src={ErrorIcon}
                                  className="mr-8"
                                  alt="error icon"
                                />
                                <span className="text-danger">
                                  {errors.email}
                                </span>
                              </div>
                            )}
                          </div>
                        </Col>
                        <Col>
                          <div className="form-floating mb-3">
                            <Field
                              type="text"
                              name="steName"
                              className={`form-control backWhite ${
                                touched.steName && errors.steName
                                  ? "danger-input"
                                  : ""
                              }`}
                              id="floatingInputsteName"
                              placeholder=""
                              value={values?.steName || raisonSocialStorage}
                              onChange={(e) => {
                                const value = e.target.value;
                                setRaisonSocialStorage(value);
                                setFieldValue("steName", value);
                              }}
                            />
                            <label
                              htmlFor="floatingInputsteName"
                              className="colorInput"
                            >
                              Raison sociale
                            </label>
                            {touched.steName && errors.steName && (
                              <div className="text-start mt-8 d-flex">
                                <img
                                  src={ErrorIcon}
                                  className="mr-8"
                                  alt="error icon"
                                />
                                <span className="text-danger">
                                  {errors.steName}
                                </span>
                              </div>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                  <Card className="mb-3 cardDemandeFo">
                    <Card.Body>
                      <Card.Title className="pb-3 colorTitle textCard">
                        2. Informations client
                      </Card.Title>
                      <Row>
                        <Col>
                          <div className="form-floating mb-3">
                            <Field
                              type="text"
                              name="clientName"
                              className={`form-control backWhite ${
                                touched.clientName && errors.clientName
                                  ? "danger-input"
                                  : ""
                              }`}
                              id="floatingInputClientName"
                              placeholder=""
                            />
                            <label
                              htmlFor="floatingInputClientName"
                              className="colorInput"
                            >
                              Nom client
                            </label>
                            {touched.clientName && errors.clientName && (
                              <div className="text-start mt-8 d-flex">
                                <img
                                  src={ErrorIcon}
                                  className="mr-8"
                                  alt="error icon"
                                />
                                <span className="text-danger">
                                  {errors.clientName}
                                </span>
                              </div>
                            )}
                          </div>
                        </Col>
                        <Col>
                          <div className="form-floating mb-3">
                            <Field
                              type="text"
                              name="clientPrenom"
                              className={`form-control backWhite ${
                                touched.clientPrenom && errors.clientPrenom
                                  ? "danger-input"
                                  : ""
                              }`}
                              id="floatingInputclientPrenom"
                              placeholder=""
                            />
                            <label
                              htmlFor="floatingInputclientPrenom"
                              className="colorInput"
                            >
                              Prénom client
                            </label>
                            {touched.clientPrenom && errors.clientPrenom && (
                              <div className="text-start mt-8 d-flex">
                                <img
                                  src={ErrorIcon}
                                  className="mr-8"
                                  alt="error icon"
                                />
                                <span className="text-danger">
                                  {errors.clientPrenom}
                                </span>
                              </div>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="form-floating mb-3">
                            <Field
                              type="text"
                              name="steClient"
                              className={`form-control backWhite ${
                                touched.steClient && errors.steClient
                                  ? "danger-input"
                                  : ""
                              }`}
                              id="floatingInputsteClient"
                              placeholder=""
                            />
                            <label
                              htmlFor="floatingInputsteClient"
                              className="colorInput"
                            >
                              Raison sociale
                            </label>
                            {touched.steClient && errors.steClient && (
                              <div className="text-start mt-8 d-flex">
                                <img
                                  src={ErrorIcon}
                                  className="mr-8"
                                  alt="error icon"
                                />
                                <span className="text-danger">
                                  {errors.steClient}
                                </span>
                              </div>
                            )}
                          </div>
                        </Col>
                        <Col>
                          <div className="form-floating mb-3">
                            <Field
                              type="text"
                              name="telephone"
                              className={`form-control backWhite ${
                                touched.telephone && errors.telephone
                                  ? "danger-input"
                                  : ""
                              }`}
                              id="floatingTelephone"
                              placeholder=""
                            />
                            <label
                              htmlFor="floatingTelephone"
                              className="colorInput"
                            >
                              Téléphone portable
                            </label>
                            {touched.telephone && errors.telephone && (
                              <div className="text-start mt-8 d-flex">
                                <img
                                  src={ErrorIcon}
                                  className="mr-8"
                                  alt="error icon"
                                />
                                <span className="text-danger">
                                  {errors.telephone}
                                </span>
                              </div>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                  <Card className="mb-3 cardDemandeFo">
                    <Card.Body>
                      <Card.Title className="pb-3 colorTitle textCard">
                        3. Informations livraison
                      </Card.Title>
                      <Row>
                        <Col>
                          <div className="form-floating mb-3">
                            <Autocomplete
                              name="pickupAdr"
                              value={pickupAdr}
                              searchOptions={searchOptions}
                              onChange={(addr) =>
                                handleChangeAddress(
                                  addr,
                                  "pickup",
                                  setFieldValue
                                )
                              }
                              onSelect={(addr) =>
                                handleSelect(addr, "pickup", setFieldValue)
                              }
                            >
                              {({
                                getInputProps,
                                suggestions,
                                getSuggestionItemProps,
                                loading,
                              }) => (
                                <div className="form-floating">
                                  <input
                                    {...getInputProps({
                                      placeholder: "",
                                      className: `form-control backWhite ${
                                        Error !== "" ? "danger-input" : ""
                                      }`,
                                    })}
                                    id="floatingPickupAdr"
                                  />
                                  <label
                                    htmlFor="floatingPickupAdr"
                                    className="colorInput"
                                  >
                                    Adresse Pick up
                                  </label>
                                  <div
                                    className={
                                      suggestions.length > 0 &&
                                      `container-autocomplete`
                                    }
                                  >
                                    {loading ? <div>Loading...</div> : null}
                                    {suggestions.map((suggestion) => {
                                      const style = {
                                        backgroundColor: suggestion.active
                                          ? "#41B6E6"
                                          : "#fff",
                                      };
                                      return (
                                        <div
                                          className="mapItem"
                                          {...getSuggestionItemProps(
                                            suggestion,
                                            {
                                              style,
                                            }
                                          )}
                                        >
                                          {suggestion.description}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                            </Autocomplete>
                            {touched.pickupAdr && errors.pickupAdr && (
                              <div className="text-start mt-8 d-flex">
                                <img
                                  src={ErrorIcon}
                                  className="mr-8"
                                  alt="error icon"
                                />
                                <span className="text-danger">
                                  {errors.pickupAdr}
                                </span>
                              </div>
                            )}
                          </div>
                        </Col>
                        <Col>
                          <div className="form-floating mb-3">
                            <Autocomplete
                              name="address"
                              value={address}
                              searchOptions={searchOptions}
                              onChange={(addr) =>
                                handleChangeAddress(addr, "liv", setFieldValue)
                              }
                              onSelect={(addr) =>
                                handleSelect(addr, "liv", setFieldValue)
                              }
                            >
                              {({
                                getInputProps,
                                suggestions,
                                getSuggestionItemProps,
                                loading,
                              }) => (
                                <div className="form-floating">
                                  <input
                                    {...getInputProps({
                                      placeholder: "",
                                      className: `form-control backWhite ${
                                        Error !== "" ? "danger-input" : ""
                                      }`,
                                    })}
                                    id="floatingAdr"
                                  />
                                  <label
                                    htmlFor="floatingAdr"
                                    className="colorInput"
                                  >
                                    Adresse Livraison
                                  </label>
                                  <div
                                    className={
                                      suggestions.length > 0 &&
                                      `container-autocomplete`
                                    }
                                  >
                                    {loading ? <div>Loading...</div> : null}
                                    {suggestions.map((suggestion) => {
                                      const style = {
                                        backgroundColor: suggestion.active
                                          ? "#41B6E6"
                                          : "#fff",
                                      };
                                      return (
                                        <div
                                          className="mapItem"
                                          {...getSuggestionItemProps(
                                            suggestion,
                                            {
                                              style,
                                            }
                                          )}
                                        >
                                          {suggestion.description}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                            </Autocomplete>
                            {touched.address && errors.address && (
                              <div className="text-start mt-8 d-flex">
                                <img
                                  src={ErrorIcon}
                                  className="mr-8"
                                  alt="error icon"
                                />
                                <span className="text-danger">
                                  {errors.address}
                                </span>
                              </div>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="form-floating">
                            <Field name="deliveryDateTime">
                            
                              {({ field, form }) => (
                                <DatePicker                             
                                  row="3"
                                  locale={fr}
                                  {...field}
                                  minDate={new Date()} // Min date is today
                                  minTime={
                                    startDate?.toDateString() ===
                                    new Date().toDateString()
                                      ?
                                       new Date(
                                          new Date().getTime() + 60 * 70 * 1000
                                        )
                                      : new Date("2200-01-01T08:00:00")
                                  }
                                  maxTime={new Date("2200-01-01T23:59:00")}
                                  selected={startDate}
                                  onChange={(date) => {
                                    setStartDate(date);
                                    form.setFieldValue(
                                      "deliveryDateTime",
                                      date
                                    );
                                  }}
                                 // readonly="readonly" 
                              
                                  showTimeSelect
                                  timeFormat="HH:mm"
                                  timeIntervals={15}
                                  timeCaption="Heure"
                                  dateFormat="dd/MM/yyyy HH:mm"
                                  className={`form-control backWhite ${
                                    touched.deliveryDateTime &&
                                    errors.deliveryDateTime
                                      ? "danger-input"
                                      : ""
                                  }`}
                                  placeholderText=""
                                />
                              )}
                            </Field>
                            <label
                              htmlFor="floatingDeliveryDateTime"
                              className="colorInput sizeInput"
                            >
                              Date et Heure de livraison
                            </label>

                            {touched.deliveryDateTime &&
                              errors.deliveryDateTime && (
                                <div className="text-start mt-8 d-flex">
                                  <img
                                    src={ErrorIcon}
                                    className="mr-8"
                                    alt="error icon"
                                  />
                                  <span className="text-danger">
                                    {errors.deliveryDateTime}
                                  </span>
                                </div>
                              )}
                          </div>
                        </Col>

                        <Col>
                          <div className="form-floating mb-3">
                            <Field
                              as="textarea"
                              name="comment"
                              className="form-control backWhite"
                              id="floatingComment"
                              placeholder=""
                              style={{ height: "100px" }}
                            />
                            <label
                              htmlFor="floatingComment"
                              className="colorInput"
                            >
                              Commentaire (optionnel)
                            </label>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <div>
                          <div className="file-input-container">
                            <div className="file-info">
                              <img
                                src={PDFIcon}
                                alt="file icon"
                                className="file-icon"
                              />
                              <div className="file-details">
                                <strong className="file-Title">
                                  Bordereau de livraison
                                </strong>
                                <p>Fichier autorisé : PDF, max. 25 MO</p>
                                {fileName && (
                                  <p className="uploaded-file-name">
                                    Fichier téléchargé : {fileName}
                                  </p>
                                )}
                              </div>
                            </div>
                            <label
                              htmlFor="file"
                              className="file-upload-button backWhite"
                            >
                              <img src={DownloadIcon} alt="download icon" />
                              Ajouter le bordereau
                            </label>
                            <input
                              id="file"
                              name="deliveryReceipt"
                              accept=".pdf"
                              type="file"
                              onChange={(event) => {
                                setFieldValue(
                                  "deliveryReceipt",
                                  event.currentTarget.files[0]
                                );
                                setFile(event.currentTarget.files[0]);
                                setFileName(
                                  event.currentTarget.files[0]
                                    ? event.currentTarget.files[0].name
                                    : ""
                                );
                              }}
                              className="file-input"
                            />
                          </div>
                          {touched.deliveryReceipt &&
                            errors.deliveryReceipt && (
                              <div className="error-message">
                                <img src={ErrorIcon} alt="error icon" />
                                <span className="text-danger">
                                  {errors.deliveryReceipt}
                                </span>
                              </div>
                            )}
                        </div>
                      </Row>
                    </Card.Body>
                  </Card>
                  <Row>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-primary btn-primaryStyle"
                        disabled={isSubmitting}
                      >
                        Valider la demande de livraison
                      </button>
                    </div>
                  </Row>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default FormSupplier;
